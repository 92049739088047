import React from 'react';
import { NavigationBar } from './navigationBar';
import { Modals } from './modal';
import { TermsOfUseModals } from './termsOfUseModal';
import { FooterTranslate } from './footerTranslate'
import { ScriptCDN } from './scriptCDN';
import { resources } from './locales.js'


export function IndexContent({ lang }) {

    const handleAutomaticLanguageChange = () => {
        change_model(0, 0)
    }

    const handleOriginLanguageChange = (lang) => {
        change_model(0, lang, 1)
    }

    const handleTranslateLanguageChange = (lang) => {
        change_model(1, lang)
    }

    const handleValorateTranslation = (value) => {
        valorate(value)
    }

    const returnBadge = (trans_lang) => {
        if (trans_lang === resources.new_badge) {
            return (<span>{"\u00a0\u00a0"}<div class="badge badge-pill badge-info">{resources[lang]["translate"]["new_notice"]}</div></span>)
        } else {
            return
        }
    }

    const returnLanguageButtonClass = (lang) => {
        if (resources.languageStatus[lang] == 0) {
            return "btn btn-danger hizkuntza_aukeraketa_botoia_new"
        } else {
            return "btn btn-danger hizkuntza_aukeraketa_botoia_new dialect"
        }
    }

    const languagesSourceBox = resources.translationLanguages.map(element => 
        <div id={`fromlang_${element}`} class="dropdown show hizkuntza_aukeraketa pl-0 ml-0">
            <a class={returnLanguageButtonClass(element)} href="javascript:void(0)" onClick={() => handleOriginLanguageChange(element)} role="button" aria-haspopup="true" aria-expanded="false">
                {resources[lang]["translate"][element]}
                {returnBadge(element)}
            </a><span>{"\u00a0"}</span>    
        </div>
    )

    const languageTranslateBox = []
    resources.translationLanguages.forEach(element => {
        if (element === resources.translationSpecial) {
            languageTranslateBox.push(
                <div id={`tolang_${element}`} class="dropdown show hizkuntza_aukeraketa hide-euskera pl-0 ml-0">
                    <a class={returnLanguageButtonClass(element)} href="javascript:void(0)" onClick={() => handleTranslateLanguageChange(element)} role="button" aria-haspopup="true" aria-expanded="false">
                        {resources[lang]["translate"][element]}
                        {returnBadge(element)}
                    </a><span>{"\u00a0"}</span>
                </div>
            )
        } else {
            languageTranslateBox.push(
                <div id={`tolang_${element}`} class="dropdown show hizkuntza_aukeraketa hide-other-languages pl-0 ml-0">
                    <a class={returnLanguageButtonClass(element)} href="javascript:void(0)" onClick={() => {handleTranslateLanguageChange(element)}} role="button" aria-haspopup="true" aria-expanded="false">
                        {resources[lang]["translate"][element]}
                        {returnBadge(element)}
                    </a><span>{"\u00a0"}</span>
                </div>
            )
        }
    })


    return (
        <div>
            <NavigationBar lang={lang}></NavigationBar>

            <div class="container container_flex_batua flex-fill" >

                <div class="row ">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-3 text-left pl-0">
                        <div class="alert alert-light alert-batua-2">{resources[lang]["translate"]["topTextn1"]}<i><img width="30px" src="/img/logo_batua2.svg" class="ld ld-tick" style={{"animation-duration":"4.0s"}}/></i> <b>{resources[lang]["translate"]["batua"]}</b> {resources[lang]["translate"]["topTextn2"]}</div>
                    </div>
                    <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-8 mx-auto mt-3 text-center">
                        <div class="alert alert-notice-batua-tenpercent"><i class="far fa-thumbs-up"></i> {resources[lang]["translate"]["topTextInprove"]}</div>
                    </div>
                </div>
                
                <div class="row testu_row">
                    <div id="testu_sarrera" class="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-9 mt-1 pl-2 pr-2">
                    <div id="card-batua-source" class="card text-center card-batua">
                        <div class="card-header">
                        <ul class="nav nav-pills card-header-pills">

                            <li class="nav-item batua-top-left" style={{"max-width":"94%"}}>
                            <div id="fromlang_auto" class="dropdown show hizkuntza_aukeraketa pl-0 ml-0">
                                <a class="btn btn-danger hizkuntza_aukeraketa_botoia_new_selected dialect auto" href="javascript:void(0)" onClick={() => {handleAutomaticLanguageChange()}} role="button" aria-haspopup="true" aria-expanded="false">
                                <img width="30px" src="/img/icons8-brain-64.png" class="ld ld-tick" style={{"animation-duration":"4.0s"}}/>
                                </a>
                            </div>{" "}
                            {languagesSourceBox}
                            </li>
                            <li class="nav-item batua-right">
                            <a class="btn btn-secondary itzuli_botoia" id="itzuli_botoia" href="javascript:void(0)" role="button">
                            <i class="fas fa-arrow-right"></i>
                            </a>
                            </li>
                        </ul>
                        </div>
                        <div class="card-body">
                        <textarea class="textarea-batuastyle clearable" spellcheck="false" autocomplete="off" placeholder={resources[lang]["translate"]["placeholderRequest"]} id="usertext_textarea"></textarea>
                        <div id="word_counter"><span id="counter_number">0</span> {resources[lang]["translate"]["wordLimit"]} <i id="clean_text_button" class="pl-2 fas fa-trash botoi_faltsua" data-toggle="tooltip" data-placement="top" title={resources[lang]["translate"]["delete"]}></i></div>
                        </div>
                    </div>
                    </div>

                    <div id="testu_irteera" class="col-12 col-lg-6 col-md-12 col-xs-9 col-sm-12 mt-1 pl-2 pr-2" >
                    <div id="card-batua-target" class="card text-center card-batua">
                        <div class="card-header">
                        <ul class="nav nav-pills card-header-pills">

                            <li class="nav-item batua-top-left">

                            <div id="lang_wait" class="dropdown show hizkuntza_aukeraketa pl-0 ml-0">
                                <a class="btn btn-danger hizkuntza_aukeraketa_botoia_new" href="javascript:void(0)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i><img width="30px" src="/img/logo_batua2.svg" class="ld ld-shake batua_wait" style={{"animation-duration":"4.0s"}}/></i>
                                </a>
                            </div>
                            {languageTranslateBox}
                            </li>
                            <li class="d-none nav-item batua-right " id="voting_box">
                            <a class="btn btn-secondary aurpegi_botoia aurpegi_pozik" id="val0" onClick={() => handleValorateTranslation(0)} href="javascript:void(0)" role="button" data-toggle="tooltip" data-placement="top" title={resources[lang]["translate"]["goodCorrection"]}>
                            <i class="fas fa-smile-beam fa-lg"></i>
                            </a>
                            <a class="btn btn-secondary aurpegi_botoia aurpegi_normal" id="val1" onClick={() => handleValorateTranslation(1)}  href="javascript:void(0)" role="button" data-toggle="tooltip" data-placement="top" title={resources[lang]["translate"]["halfNhalfCorrection"]}>
                            <i class="fas fa-meh fa-lg"></i>
                            </a>
                            <a class="btn btn-secondary aurpegi_botoia aurpegi_triste" id="val2" onClick={() => handleValorateTranslation(2)}  href="javascript:void(0)" role="button" data-toggle="tooltip" data-placement="top" title={resources[lang]["translate"]["badCorrection"]}>
                            <i class="fas fa-frown fa-lg"></i>
                            </a>
                            </li>
                        </ul>
                        </div>
                        <div class="card-body" id="text-correction-box">
                        <textarea class="textarea-batuastyle" spellcheck="false" placeholder={resources[lang]["translate"]["placeholderTranslation"]} id="translation_textarea"></textarea>
                        <div id="action_container">
                            <span id="classic_correction_mode" class="action_container_text"><span id="correction_mode_text">{resources[lang]["translate"]["correctionHelpText"]}</span><button id="modern_correction_mode" type="submit" class="btn btn-warning btn-block btn-rounded btn-proposatu z-depth-1" style={{"display":"none"}}><i class="fa fa-paper-plane pr-2"></i> {resources[lang]["translate"]["sendCorrection"]}</button><button id="enable_correction_mode" type="submit" class="btn btn-outline-secondary btn-block btn-rounded btn-proposatu z-depth-1" style={{"display":"none"}}><i class="fa fa-pen-alt pr-2"></i> {resources[lang]["translate"]["proposeCorrection"]}</button></span>

                            <i id="download_text_button" class="pl-2 fas fa-download botoi_faltsua" data-toggle="tooltip" data-placement="top" title={resources[lang]["translate"]["download"]}></i>
                            {" "}
                            <i id="copy_text_button" class="pl-2 fas fa-copy botoi_faltsua" data-toggle="tooltip" data-placement="top" title={resources[lang]["translate"]["copy"]}></i>
                        </div>

                        <div id="correction_container">
                            <span class="action_container_text "><button id="cancel-correction" type="submit" class="btn btn-light btn-block btn-rounded btn-zuzendu z-depth-1">{resources[lang]["translate"]["cancel"]}</button></span>
                            <span class="action_container_text_left"><button id="submit-correction" type="submit" class="btn btn-danger btn-block btn-rounded btn-zuzendu z-depth-1">{resources[lang]["translate"]["sendCorrection"]}</button></span>
                        </div>

                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <Modals lang={lang}></Modals>
            <TermsOfUseModals lang={lang}></TermsOfUseModals>
            <FooterTranslate lang={lang}></FooterTranslate>
            <ScriptCDN lang={lang} page="translate"></ScriptCDN>
        </div>
    );
}
