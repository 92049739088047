import React from 'react';
import { resources } from './locales.js'

export function TermsOfUseModals({ lang }) {

    /*console.log(resources[lang])
    console.log(resources[lang]["termsModal"])
    console.log(resources[lang]["termsModal"]["title"])*/

    return (

        <div id="terms-acceptance-modal" class="modal fade hide" tabindex="-1" role="dialog" aria-labelledby="terms-acceptance-modal" aria-hidden="true">
            <div class="modal-dialog cascading-modal" role="document">
                <div class="modal-content card card-image" style={{"background-image":"url('/img/background.png')", "background-color":"rgba(0, 0, 0, 1) !important", "background-position":"center", "background-repeat":"no-repeat", "background-size":"auto"}}>
                    <div class="text-white rgba-stylish-strong py-5 px-5 z-depth-4">
                        <div class="modal-header text-center pb-4">
                            <h3 class="modal-title w-100 font-weight-bold heading"><strong>{resources[lang]["termsModal"]["title"]}</strong></h3>
                        </div>
                        <div class="modal-header text-center pb-4">
                            <p><strong>{resources[lang]["termsModal"]["messagen1"]}<a href={`/${resources[lang]["paths"]["terms"]["slug"]}/`} target="_blank">{resources[lang]["paths"]["terms"]["text"]}</a>{resources[lang]["termsModal"]["messagen2"]}<a href={`/${resources[lang]["paths"]["privacy"]["slug"]}/`} target="_blank">{resources[lang]["paths"]["privacy"]["text"]}</a>{resources[lang]["termsModal"]["messagen3"]}</strong></p>
                        </div>
                        <div class="modal-body" style={{"background-color":"rgba(255, 255, 255, 0.7)"}}>
                            <form class="needs-validation" novalidate>
                                <div class="accept-action-container">
                                    <div class="row mt-1 mb-2">
                                        <div class="col-1"></div>
                                        <div class="col-2"><input class="form-check-input" type="checkbox" value="" id="terms-acceptance-modal-checkbox" required=""/></div>
                                        <div class="col-8 text-left">
                                            <label class="form-check-label" for="terms-acceptance-modal-checkbox">{resources[lang]["termsModal"]["acceptance"]}</label>
                                            <div class="invalid-feedback">
                                                {resources[lang]["termsModal"]["feedback"]}
                                            </div>
                                        </div>
                                        <div class="col-1"></div>
                                    </div>
                                    <div class="row d-flex align-items-center">
                                        <div class="text-center col-md-12">
                                            <button id="terms-acceptance-modal-accept-button" type="submit" class="btn btn-success btn-block btn-lg btn-rounded z-depth-1 h-100">{resources[lang]["termsModal"]["submit"]}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="modal-footer justify-content-center pt-5 pb-0 mb-0">
                            <button type="button" class="btn btn-danger btn-sm btn-block btn-rounded z-depth-1" data-dismiss="modal">{resources[lang]["termsModal"]["dismiss"]}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}