import React  from 'react';
import { resources, getHrefCode } from './locales.js'


export function FooterTranslate({ lang }) {

    var pathTerms = resources[lang]["paths"]["terms"]["slug"]
    var pathPrivacy = resources[lang]["paths"]["privacy"]["slug"]
    var pathPolicy = resources[lang]["paths"]["policy"]["slug"]

    var langPrefix = `${getHrefCode(lang)}`

    return (

        <footer class="batua_footer mt-5">
            <div class="d-flex flex-row justify-content-around">
                <div class="pl-4 d-none d-lg-block col-6 pb-0 mb-0 text-left">
                    <figure class="figure mb-0" >
                        <a href="http://vicomtech.org" target="_blank"><img src="/img/vicomtech_logo.svg" width="120em" class="figure-img img-fluid rounded footer_img" alt="Vicomtech logo."/></a>
                    </figure>
                    <br/>
                </div>
                <div class="d-none d-lg-block col-6 pb-0 mb-0 text-right pr-4">
                    <span id="legal" class="pt-4"><a href={`/${langPrefix}${pathTerms}`}>{resources[lang]["paths"]["terms"]["text"]}</a> / <a href={`/${langPrefix}${pathPrivacy}`}>{resources[lang]["paths"]["privacy"]["text"]}</a> / <a href={`/${langPrefix}${pathPolicy}`}>{resources[lang]["paths"]["policy"]["text"]}</a>{" - "}{process.env.GATSBY_BATUA_FRONTEND_VERSION}</span>
                </div>
            </div>

            <div class="flex-row">
                <div class="col-12 d-block d-lg-none">
                    <figure class="figure">
                        <a href="http://vicomtech.org" target="_blank"><img src="/img/vicomtech_logo.svg" width="120em" class="figure-img img-fluid rounded footer_img" alt="Vicomtech logo."/></a>
                    </figure>
                </div>
                <div class="col-12 d-block d-lg-none pt-0">
                    <span id="legal" class="mobile"><a href={`/${langPrefix}${pathTerms}`}>{resources[lang]["paths"]["terms"]["text"]}</a> / <a href={`/${langPrefix}${pathPrivacy}`}>{resources[lang]["paths"]["privacy"]["text"]}</a> / <a href={`/${langPrefix}${pathPolicy}`}>{resources[lang]["paths"]["policy"]["text"]}</a></span>
                </div>
            </div>
        </footer>
    

    )
}